import languagePack from '../assets/languages';
import axios from "axios";
import ConfigController from './configController';

const LanguageController = {

    register(update) {
        this.forceUpdate = update;
    },

    availableLanguages() {
        
        if (this.allowedLanguages) return this.allowedLanguages;

        this.allowedLanguages = ConfigController.retrieveAvailableCountries().map((x) => x.item.split(".")[1]);

        for (let item in this.allowedLanguages) {
            if (!(item in languagePack)) 
                languagePack[item] = { fallback: "at", content: {} };
        }

        return this.allowedLanguages;
    },

    changeLanguage(language, update) { // changes language only if valid (+ saves to localstorage)

        if (this.availableLanguages().indexOf(language) !== -1) { // die sprache die ausgewhält wurde, muss auch verfügbar sein
            this.currentLanguage = language;
            localStorage.setItem("lang", language);

            if (update) this.forceUpdate();

            // CS 16/9/21: refresh all lists of configController (like jobTypes) to match new language
            ConfigController.changeOfLanguage(language);
        }
    },

    determineLanguage() {

        // CS 16/3/22: already cached?
        if (this.currentLanguage) return this.currentLanguage;

        let lang = localStorage.getItem("lang"); // read last saved language from localstorage
        if (lang) {
            this.changeLanguage(lang, false); // if valid language -> return
            if (this.currentLanguage === lang) return this.currentLanguage;
        }

        if (!this.currentLanguage) { // determine language using ip address
            this.currentLanguage = "at"; // set at as default language
            // CS 20/7/2021
            // call ipAPI for determining clients location
            axios.get("https://api.ipapi.com/api/check?access_key=da7257ec9511da8896052a9712cd2073").then((response) => {
                this.changeLanguage(response.data["country_code"].toLowerCase());
            });
        }

        return this.currentLanguage;
    },

    // returns true if the current users country-code matches the provided country-code.
    // The check is case-insensitive
    currentCountryIs(countryCode)
    {
        var currentCountry = this.determineLanguage();
        return currentCountry.toUpperCase() == countryCode.toUpperCase();
    },

    determineLanguageCountryId() {
        let language = this.determineLanguage();
        return ConfigController.retrieveAvailableCountries().filter(x => x.item.includes(language))[0].id;
    },
    
    // CS 4/7/22: added function to find out if current country is an EWR member or not
    determineLanguageCountryEWR() {
        let language = this.determineLanguage();
        return ConfigController.retrieveAvailableCountries().filter(x => x.item.includes(language))[0].ewr;
    },

    translate(token, language) {

        // CS 29/11/22: added fallback to '-undefined-' if token is null
        if (token == null) return '-undefined-';
        
        language = language || this.determineLanguage();

        if (!(language in languagePack)) return token;

        var tokenItems = token.split(".");
        var result = languagePack[language].content;

        tokenItems.forEach(item => {
            if (result) result = result[item];
        });

        if (!result || typeof (result) !== "string") {
            if (!languagePack[language].fallback) return token;
            return this.translate(token, languagePack[language].fallback);
        }
        return result;
    },

    //
    // CS 8/9/21: translate only if token exists, otherwise return empty string
    //
    translateOrDefault(token, defaultValue = '', language) {

        // CS 29/11/22: added fallback to '-undefined-' if token is null
        if (token == null) return '-undefined-';

        language = language || this.determineLanguage();
        if (!(language in languagePack)) return defaultValue;

        var tokenItems = token.split(".");
        var result = languagePack[language].content;

        tokenItems.forEach(item => {
            if (result) result = result[item];
        });

        // CS 3/5/22: fixed issue with missing fallback search in _if calls
        //if (!result || typeof (result) !== "string") return "";
        if (!result || typeof (result) !== "string"){
            if (!languagePack[language].fallback) return defaultValue;
            return this.translateOrDefault(token, defaultValue, languagePack[language].fallback);
        }


        return result;
    }

}

export default LanguageController;
export function _(token, country) { return LanguageController.translate(token, country); }
// CS 8/9/21: translate only if token exists, otherwise returns empty string or optionally provided defaultValue
export function _try(token, defaultValue = '', country) { return LanguageController.translateOrDefault(token, defaultValue); }