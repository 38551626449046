import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem
} from 'reactstrap';
import { _ } from '../../controllers/languageController';
import AuthenticationController from '../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import ConfigController from '../../controllers/configController';
//import UserSession from '../../userSession';

class NavigationComponent extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout() {
        AuthenticationController.logout();
        this.props.history.push("/");
    }

    retrieveImpersonationPanel()
    {
        var token = AuthenticationController.retrieveContent();
        // if impersonatingAccountID show the impersonation mode
        if ( token && token.impersonatingAccountID)
        {
            return (

                <div className="impersonation-panel" style={this.cardHeaderStyle}>
                    <a href="#" onClick={(e) => this.terminateImpersonation(e) } className="terminate-impersonation side-by-side">
                        <span class="material-icons">admin_panel_settings</span>
                        Terminate Impersonation
                    </a>
                </div>

            );
        }
        return null;
    }

    /* 
        CS: 21/12/2021
        initiates the impersonation of an account (person or pharmacy)
    */
    terminateImpersonation = (e, accountId) =>
    {
        e.preventDefault();

        ApiController.adminTerminateImpersonation(callbackWrapper((response) => {

            if (response.data.error) {
                NotificationController.pushError(_(response.data.error));
                return;
            }

            // response.data.result holds the token
            var token = response.data.result;

            NotificationController.pushSuccess(_("success.impersonationTerminated"));
            // set current token
            AuthenticationController.setToken(token);

            this.props.history.push("/");
    
            }).bind(this));
        }
    

    retrieveMenu() {
        switch (this.props.location.pathname.split("/")[1]) {
            case "login":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/register"><b>{_("nav.register")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            case "register":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/login"><b>{_("nav.login")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            case "profile":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/" onClick={this.logout.bind(this)}><b>{_("nav.logout")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            case "edit":
            case "create":
            case "match":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/profile"><b>{_("nav.profile")}</b></Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/" onClick={this.logout.bind(this)}><b>{_("nav.logout")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            case "settings":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/profile"><b>{_("nav.profile")}</b></Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/" onClick={this.logout.bind(this)}><b>{_("nav.logout")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            case "admin":
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/" onClick={this.logout.bind(this)}><b>{_("nav.logout")}</b></Link>
                        </NavItem>
                    </Nav>
                );
            default:
                return (
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/login"><b>{_("nav.login")}</b></Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link apo-link-secondary" to="/register"><b>{_("nav.register")}</b></Link>
                        </NavItem>
                    </Nav>
                );

        }
    }

    render() {
        /*         
                var userInfo = "UNKNOWN";
                var postalInfo = "N/A";

                if ( UserSession.getAccount().account !== undefined) userInfo = UserSession.getAccount().account.email;
                if ( UserSession.getPostalArea() !== undefined) postalInfo = UserSession.getPostalArea().name;
        */

        if ( ConfigController.isHealthcareEdition()) return this.renderHealthcareEdition();

        return this.renderPharmacyEdition();

    }

    renderPharmacyEdition() {

        return (
            <div className="px-md-4 pt-2">
                {/* 
                    Christian S. disabled collapsing of navbar since we have only a single menu anyway which fits on every media
                        <Navbar light expand="md"> 
                    changed to 
                        <Navbar light expand="xs"> 
                */}
                <Navbar light expand="md">
                    {/* <NavbarBrand href="https://apopersonal.com"><img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="d-inline-block align-top" alt="Apopersonal Logo" style={{ maxHeight: "38px", height: "auto", maxWidth: "100%" }} /></NavbarBrand> */}
                    <NavbarBrand href="https://app.apopersonal.com"><img src={`${process.env.PUBLIC_URL}/assets/images/apopersonal-linda.svg?v=2.0`} className="apo-logo d-inline-block align-top" alt="Apopersonal Logo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        {this.retrieveMenu()}
                    </Collapse>
                </Navbar>
                {this.retrieveImpersonationPanel()}
            </div >
        );
    }

    renderHealthcareEdition() {

        return (
            <div className="px-md-4 pt-2">
                <Navbar light expand="md">
                    <NavbarBrand href="https://app.pflege-finden.com"><img src={`${process.env.PUBLIC_URL}/assets/images/pflege-finden.svg?v=1.0`} className="pflege-logo d-inline-block align-top" alt="Pflege-finden Logo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        {this.retrieveMenu()}
                    </Collapse>
                </Navbar>
                {this.retrieveImpersonationPanel()}
            </div >
        );
    }

}

export default withRouter(NavigationComponent);