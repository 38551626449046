import jwt from 'jsonwebtoken';

const AuthenticationController = {

    retrieveToken() {
        if (this.currentToken) {
            return this.currentToken;
        }

        this.currentToken = localStorage.getItem("token");
        return this.currentToken;
    },

    retrieveContent() {
        return jwt.decode(this.retrieveToken());
    },

    setToken(token) {
        this.currentToken = token;
        localStorage.setItem("token", token);
    },

    isAuthenticated() {
        // CS 14/5/20: removed the IsAdmin check to allow administrator to logon as well
        //return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && !this.isAdmin();
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken();
    },

    isMandatoryChange() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.pendingMandatoryChange === "True";
        }
        return false;
    },

    isPerson() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.type === "True";
        }
        return false;
    },

    isAdmin() {
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && this.retrieveContent().admin === "True";
    },

    logout() {
        this.setToken(undefined);
    }

}

export default AuthenticationController;